import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "menu__list" }
const _hoisted_2 = { class: "menu__item" }
const _hoisted_3 = { class: "menu__item" }
const _hoisted_4 = { class: "menu__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("div", {
    class: ["menu", [{'menu--open': _ctx.isOpen}]]
  }, [
    _createVNode("ul", _hoisted_1, [
      _createVNode("li", _hoisted_2, [
        _createVNode(_component_router_link, {
          class: "menu__link",
          to: {name: 'Home'}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translations.home), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "menu__link",
          to: {name: 'Classes'}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translations.classes), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "menu__link",
          to: {name: 'About'}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translations.about), 1)
          ]),
          _: 1
        })
      ])
    ])
  ], 2))
}