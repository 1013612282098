import LandingPage from '@/views/Landing/LandingPage.vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About/About.vue'),
  },
  {
    path: '/classes',
    name: 'Classes',
    component: () => import('@/views/Classes/Classes.vue'),
  },
  {
    path: '/coworking',
    name: 'Coworking',
    component: () => import('@/views/Coworking/Coworking.vue'),
  },
  {
    path: '/thelittlepot',
    name: 'TheLittlePot',
    component: () => import('@/views/TheLittlePot/TheLittlePot.vue'),
  },
  {
    path: '/sample',
    name: 'Sample',
    redirect: {name: 'SampleStyleGuide'},
    component: () => import('@/views/StyleGuide/Sample.vue'),
    children: [{
      path: 'styleguide',
      name: 'SampleStyleGuide',
      component: () => import('@/views/StyleGuide/SampleStyleGuide.vue'),
    }, {
      path: 'colors',
      name: 'SampleColors',
      component: () => import('@/views/StyleGuide/SampleColors.vue'),
    }, {
      path: 'button',
      name: 'SampleButton',
      component: () => import('@/components/Button/SampleButton.vue'),
    }, {
      path: 'icon',
      name: 'SampleIcon',
      component: () => import('@/components/Icon/SampleIcon.vue'),
    }, {
      path: 'table',
      name: 'SampleTable',
      component: () => import('@/components/Table/SampleTable.vue'),
    }, {
      path: 'inputText',
      name: 'SampleInputText',
      component: () => import('@/components/Input/Text/SampleInputText.vue'),
    }, {
      path: 'inputTextArea',
      name: 'SampleInputTextArea',
      component: () => import('@/components/Input/TextArea/SampleInputTextArea.vue'),
    }, {
      path: 'inputCheckbox',
      name: 'SampleInputCheckbox',
      component: () => import('@/components/Input/Checkbox/SampleInputCheckbox.vue'),
    }, {
      path: 'inputSwitch',
      name: 'SampleInputSwitch',
      component: () => import('@/components/Input/Switch/SampleInputSwitch.vue'),
    }, {
      path: 'inputSelect',
      name: 'SampleInputSelect',
      component: () => import('@/components/Input/Select/SampleInputSelect.vue'),
    }],
  },
  {
    path: '/dev',
    name: 'SkipMaintenance',
    component: () => import('../views/Maintenance/SkipMaintenance.vue'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/Maintenance/Maintenance.vue'),
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('../views/Error/Forbidden.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/Error/PageNotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  /* const maintenance = localStorage.maintenance && localStorage.maintenance === 'false' ? false : true;
  if (to.name !== 'SkipMaintenance' && to.name !== 'Maintenance' && maintenance) {
    next({name: 'Maintenance'});
  } else {
    next();
  } */
  next();
});

export default router;
