
import { computed, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Menu',
  setup() {
    const { t } = useI18n();
    const isOpen = ref<boolean>(false);
    const translations = computed(() => {
      return {
        home: t('components.menu.home'),
        classes: t('components.menu.classes'),
        coworking: t('components.menu.coworking'),
        tlp: t('components.menu.tlp'),
        about: t('components.menu.about'),
      };
    });

    function toggleMenu() {
      isOpen.value = !isOpen.value;
    }

    function closeMenu() {
      isOpen.value = false;
    }

    return {
      translations,
      isOpen,
      toggleMenu,
      closeMenu,
    };
  },
});
