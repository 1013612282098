
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import Icon from '@/components/Icon/Icon.vue';
import { Size } from '@/models/components/size.model';
import { ThemeButton } from '@/models/components/theme.model';
import { Icons } from '@/models/components/icon.model';

export default defineComponent({
  name: 'Button',
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String as PropType<Icons>,
      default: undefined,
    },
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
      default: undefined,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reverseOrder: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<Size>,
      default: 'md',
    },
    theme: {
      type: String as PropType<ThemeButton>,
      default: 'default',
    },
  },
  emits: ['clicked'],
  setup(props, {emit}) {
    function clicked(event: Event) {
      if (!props.disabled) {
        emit('clicked', event);
      }
    }
    return {
      clicked,
    };
  },
});
