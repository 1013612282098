import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "button__icon"
}
const _hoisted_2 = {
  key: 1,
  class: "button__text"
}
const _hoisted_3 = {
  key: 0,
  class: "button__icon"
}
const _hoisted_4 = {
  key: 1,
  class: "button__text"
}
const _hoisted_5 = {
  key: 0,
  class: "button__icon"
}
const _hoisted_6 = {
  key: 1,
  class: "button__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")
  const _component_router_link = _resolveComponent("router-link")

  return (_ctx.to && !_ctx.disabled)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: ["button", [{'button--block': _ctx.block, 'button--reverse': _ctx.reverseOrder}, 'button--' + _ctx.theme, 'button--' + _ctx.size]],
        title: _ctx.title,
        to: _ctx.to
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock("div", _hoisted_1, [
                _createVNode(_component_Icon, {
                  name: _ctx.icon,
                  size: _ctx.size
                }, null, 8, ["name", "size"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.text)
            ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "to", "class"]))
    : (_ctx.href && !_ctx.disabled)
      ? (_openBlock(), _createBlock("a", {
          key: 1,
          class: ["button", [{'button--block': _ctx.block, 'button--reverse': _ctx.reverseOrder}, 'button--' + _ctx.theme, 'button--' + _ctx.size]],
          title: _ctx.title,
          href: _ctx.href,
          target: "_blank",
          rel: "noopener noreferrer"
        }, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode(_component_Icon, {
                  name: _ctx.icon,
                  size: _ctx.size
                }, null, 8, ["name", "size"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.text)
            ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true)
        ], 10, ["title", "href"]))
      : (_openBlock(), _createBlock("button", {
          key: 2,
          class: ["button", [{'button--block': _ctx.block, 'button--reverse': _ctx.reverseOrder, 'button--disabled': _ctx.disabled}, 'button--' + _ctx.theme, 'button--' + _ctx.size]],
          title: _ctx.title,
          disabled: _ctx.disabled,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args)))
        }, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock("div", _hoisted_5, [
                _createVNode(_component_Icon, {
                  name: _ctx.icon,
                  size: _ctx.size
                }, null, 8, ["name", "size"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.text)
            ? (_openBlock(), _createBlock("div", _hoisted_6, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true)
        ], 10, ["title", "disabled"]))
}