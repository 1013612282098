
import { defineComponent, onMounted, PropType, ref, Ref } from 'vue';
import { getUId } from '@/utils/UIdUtil';
import { Size } from '@/models/components/size.model';

export default defineComponent({
  name: 'InputSwitch',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    labelHidden: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<Size>,
      default: 'md',
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const inputId: Ref<string> = ref('');
    
    onMounted(() => {
      inputId.value = 'input-switch-' + getUId();
    });

    function toggle(event: Event) {
      emit('update:modelValue', (event.target as HTMLInputElement).checked);
    }

    return {
      toggle,
      inputId,
    };
  },
});
