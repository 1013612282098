import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header__burguer" }
const _hoisted_2 = { class: "header__logo" }
const _hoisted_3 = { class: "header__menu" }
const _hoisted_4 = { class: "header__actions" }
const _hoisted_5 = { class: "header__social" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_Icon = _resolveComponent("Icon")
  const _component_router_link = _resolveComponent("router-link")
  const _component_NavMenu = _resolveComponent("NavMenu")

  return (_openBlock(), _createBlock("div", {
    class: "header",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        icon: "menu",
        title: "Menu",
        theme: "transparent",
        onClicked: _withModifiers(_ctx.toggleMenu, ["stop"])
      }, null, 8, ["onClicked"])
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "header__logo-link",
        to: {name: 'Home'}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, {
            name: "logo-cou-text",
            width: "5rem",
            height: "1.5rem"
          })
        ]),
        _: 1
      })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_NavMenu, { ref: "menuRef" }, null, 512)
    ]),
    _createVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "actions")
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_Button, {
        icon: "instagram",
        href: "https://www.instagram.com/couceramica/",
        theme: "text",
        title: "Instagram CouCeramica"
      }),
      _createVNode(_component_Button, {
        icon: "etsy",
        href: "https://www.etsy.com/es/shop/thelittlepotceramics",
        theme: "text",
        title: "Etsy"
      })
    ])
  ]))
}