import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("svg", {
    class: ["icon", ['icon--'+_ctx.size]],
    style: _ctx.setCustomVariables()
  }, [
    (_ctx.title)
      ? (_openBlock(), _createBlock("title", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createBlock("desc", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createVNode("use", {
      "xlink:href": '#'+_ctx.name
    }, null, 8, ["xlink:href"])
  ], 6))
}