import { createI18n } from 'vue-i18n';
import componentsCa from './componentsCa.json';
import componentsEn from './componentsEn.json';
import componentsEs from './componentsEs.json';
import pagesCa from './pagesCa.json';
import pagesEn from './pagesEn.json';
import pagesEs from './pagesEs.json';

export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: localStorage.locale || 'es',
    fallbackLocale: 'es',
    messages: {
        ca: {
            components: componentsCa,
            pages: pagesCa,
        },
        en: {
            components: componentsEn,
            pages: pagesEn,
        },
        es: {
            components: componentsEs,
            pages: pagesEs,
        },
    },
});