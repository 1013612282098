
import { defineComponent, ref } from 'vue';
import Button from '@/components/Button/Button.vue';
import Icon from '@/components/Icon/Icon.vue';
import NavMenu from '@/layout/components/NavMenu/NavMenu.vue';

export default defineComponent({
  name: 'Header',
  components: {
    Button,
    Icon,
    NavMenu,
  },
  setup() {
    const menuRef = ref();

    function toggleMenu() {
      menuRef.value.toggleMenu();
    }

    function closeMenu() {
      menuRef.value.closeMenu();
    }

    return {
      toggleMenu,
      menuRef,
      closeMenu,
    };
  },
});
