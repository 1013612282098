import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "select__container" }
const _hoisted_2 = {
  key: 0,
  value: undefined
}
const _hoisted_3 = {
  key: 0,
  class: "select__icon select__icon--prepend"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")

  return (_openBlock(), _createBlock("div", {
    class: ["select", [{'select--block': _ctx.block, 'select--error': _ctx.error}, 'select--' + _ctx.size]],
    title: _ctx.label
  }, [
    _createVNode("label", {
      class: ["select__label text--medium", {'sr-only': _ctx.labelHidden}],
      for: _ctx.inputId
    }, _toDisplayString(_ctx.label), 11, ["for"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("select", {
        class: ["select__input text-body--md", {'select__input--block': _ctx.block, 'select__input--error': _ctx.error, 'select__input--icon-prepend': _ctx.prependIcon}],
        id: _ctx.inputId,
        name: _ctx.name,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (_ctx.placeholder)
            ? (_openBlock(), _createBlock("option", _hoisted_2, _toDisplayString(_ctx.placeholder), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.optionList, (option) => {
            return (_openBlock(), _createBlock("option", {
              value: option.key
            }, _toDisplayString(option.value), 9, ["value"]))
          }), 256))
        ])
      ], 42, ["id", "name", "value", "disabled"]),
      (_ctx.prependIcon)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode(_component_Icon, {
              name: _ctx.prependIcon,
              size: _ctx.iconSize
            }, null, 8, ["name", "size"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 10, ["title"]))
}