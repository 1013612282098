
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import LayoutBase from '@/layout/LayoutBase.vue';
import Header from '@/layout/components/Header/Header.vue';
import Footer from '@/layout/components/Footer/Footer.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'App',
  components: {
    LayoutBase,
    Header,
    Footer,
  },
  setup() {
    const { locale } = useI18n();
    const systemDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
    const darkTheme = ref<boolean>(false);
    const themeAutoChangeX = ref(false);

    onBeforeMount(() => {
      initLocale();
      initTheme();
    });

    watch(locale, (newVal) => {
      localStorage.locale = newVal;
    });
    
    function initLocale() {
      /* if (localStorage.locale) {
        locale.value = localStorage.locale;
      } else if (/^en\b/.test(navigator.language)) {
        locale.value = 'en';
      } else {
        locale.value = 'es';
        localStorage.locale = 'es';
      } */
      locale.value = 'es';
      localStorage.locale = 'es';
    }

    function initTheme() {
      if (localStorage.theme) {
        darkTheme.value = localStorage.theme === 'true';
      } else if (systemDarkTheme.matches) {
        themeAutoChangeX.value = true;
        darkTheme.value = true;
      } else {
        darkTheme.value = false;
      }
      addThemeListener();
    }

    function addThemeListener() {
      systemDarkTheme.addEventListener('change', (event) => {
        if (!localStorage.theme) {
          themeAutoChangeX.value = true;
          darkTheme.value = event.matches;
        }
      });
    }

    watch(darkTheme, (newVal) => {
      if (!themeAutoChangeX.value) {
        localStorage.theme = darkTheme.value;
      }
      themeAutoChangeX.value = false;
      toggleTheme(newVal);
    });

    function toggleTheme(dark: boolean) {
      const html: HTMLHtmlElement | null = document.querySelector('html');
      if (html) {
        html.className = (dark ? 'dark' : 'light') + '-theme';
      }
    }

    return {
      darkTheme,
    };
  },
});
