
import { defineComponent, onMounted, ref } from 'vue';
import InputSwitch from '@/components/Input/Switch/InputSwitch.vue';
import Icon from '@/components/Icon/Icon.vue';
import InputSelect from '@/components/Input/Select/InputSelect.vue';
import Button from '@/components/Button/Button.vue';
import { useI18n } from 'vue-i18n';
import { languages } from '@/models/components/languages.model';

export default defineComponent({
  name: 'Footer',
  components: {
    InputSwitch,
    Icon,
    InputSelect,
    Button,
  },
  props: {
    darkTheme: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:darkTheme'],
  setup(props, {emit}) {
    const { locale } = useI18n();
    const userDarkTheme = ref<boolean>(false);

    onMounted(() => {
      userDarkTheme.value = props.darkTheme;
    });
    
    function toggleTheme() {
      emit('update:darkTheme', userDarkTheme.value);
    }

    return {
      userDarkTheme,
      toggleTheme,
      language: locale,
      languages,
    };
  },
});
