
import { defineComponent, PropType } from 'vue';
import { SizeX } from '@/models/components/size.model';
import { Icons } from '@/models/components/icon.model';

export default defineComponent({
  name: 'Icon',
  components: {
  },
  props: {
    name: {
      type: String as PropType<Icons>,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<SizeX>,
      default: 'md',
    },
    color: {
      type: String,
      default: undefined,
    },
    customSize: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    function setCustomVariables(): string {
      let cssProperties = '';
      if (props.width && props.height) {
        cssProperties += '--icon-width:' + props.width + ';';
        cssProperties += '--icon-height:' + props.height + ';';
      } else if (props.customSize) {
        cssProperties += '--icon-width:' + props.customSize + ';';
        cssProperties += '--icon-height:' + props.customSize + ';';
      }
      if (props.color) {
        cssProperties += '--icon-color:' + props.color + ';';
      }
      return cssProperties;
    }

    return {
      setCustomVariables,
    };
  },
});
