
import { defineComponent } from 'vue';
import Icon from '@/components/Icon/Icon.vue';
import SectionClasses from './components/SectionClasses.vue';
import SectionCoworking from './components/SectionCoworking.vue';
import SectionAbout from './components/SectionAbout.vue';

export default defineComponent({
  name: 'LandingPage',
  components: {
    Icon,
    SectionClasses,
    SectionCoworking,
    SectionAbout,
  },
});
